import {observable, action, computed, toJS, makeObservable} from 'mobx';
import {makeAuthenticatedRequest, makeAuthenticatedDownloadRequest} from 'utils/API';
import moment from 'moment';

import {pastYearMonthsArray} from '../utils/Date';
import { AlertKey } from 'views/main/dashboard/AlertsTable';
import { Mitigation } from './AlertsProvider';

type AlertDevice = {
    id: string;
    ip_address: string;
    hostname: string;
    policy_id?: string;
    type: string,
    user_id: string,
    windows_allowed_processes?: string
};
export type Alert = {
    id: string;
    threat_type: string;
    severity: string;
    category: string
    timestamp: number;
    device: AlertDevice,
    description: string
    mitigated: boolean
};
type AlertsData = {
    alerts: Alert[],
    all_alerts: Alert[]
}
export type Metadata = {
    cursor_before?: any;
    cursor_after?: any;
    cursor_last?: any;
    cursor_current: string;
    page_length?: number;
}
type topThreatlist = {
    labels?: [],
    values?: []
}
type alertsPerMonth = {
    labels?: [],
    values?: []
}
export type Order = 'asc' | 'desc'
export type LoadOptions = {
    severitiesFilter?: Severity
    categoriesFilter?: Category
    sortBy?: AlertKey
    order?: Order
    mitigation?: Mitigation
}
export type Severity = 'ransomware' | 'siem' | 'ids' | 'all'
export type Category = 'hardening' | 'detection' | 'mitigation' | 'all'

export class AlertsStore {
    public _loading: boolean;
    public _data: AlertsData;
    public totalAlertsCount: number;
    public totalDeviceAlertsCount: number;
    public cursor_before: string;
    public cursor_after: string;
    public cursor_last: string;
    public cursor_current: string;
    public data_cursor_last: string;
    public data_cursor_before: string;
    public data_cursor_after: string;
    public topattacked: Array<any>;
    public topthreatlist: topThreatlist;
    public alertspermonth: alertsPerMonth;
    public severities: Severity;
    public categories: string;
    public sortBy: AlertKey;
    public order: Order;

    constructor() {
        makeObservable(this, {
            _data: observable,
            _loading: observable,
            totalAlertsCount: observable,
            totalDeviceAlertsCount: observable,
            cursor_after: observable,
            cursor_before: observable,
            cursor_last: observable,
            cursor_current: observable,
            data_cursor_last: observable,
            data_cursor_after: observable,
            data_cursor_before: observable,
            severities: observable,
            categories: observable,
            sortBy: observable,
            order: observable,
            topattacked: observable,
            topthreatlist: observable,
            alertspermonth: observable,
            data: computed,
            loading: computed,
            onApiFailure: action,
            onLoadDataSuccess: action,
            onLoadAllDataSuccess: action,
            onLoadTopAttacked: action,
            onLoadTopThreats: action,
            onLoadAlertsPerMonth: action,

            loadData: action,
            loadAllAlerts: action,
            loadCountAlerts: action,
            loadTopAttacked: action,
            loadTopThreats: action,
            loadAlertsPerMonth: action
        });

        this._loading = false;
        this.cursor_after = "";
        this.cursor_before = "";
        this.cursor_last = "";
        this.cursor_current = "";
        this.data_cursor_last = "";
        this.data_cursor_after = "";
        this.data_cursor_before = "";
        this.totalAlertsCount = 0;
        this.totalDeviceAlertsCount = 0;
        this.topattacked = [];
        this.topthreatlist = {
            labels: [],
            values: []
        }
        this.alertspermonth = {
            labels: [],
            values: []
        }
        this._data = {
            alerts: [],
            all_alerts: []
        };
        this.severities = "ids";
        this.categories = "detection";
        this.sortBy = 'timestamp'
        this.order = 'desc'
    }

    public get loading(): boolean {
        return toJS(this._loading);
    }

    public get data(): AlertsData {
        return toJS(this._data);
    }

    public get totalAlerts(): any {
        return this.data.alerts?.length;
    }

    public get topAttackedDevice(): any {
        const devices: any = [];

        this.data.all_alerts.forEach((alert: any) => {
            if (devices[alert.device.hostname]) {
                devices[alert.device.hostname]++;
            } else {
                devices[alert.device.hostname] = 1;
            }
        })


        const arrayDevices = Object.keys(devices).map((dev: any) => {
            return {name: dev, value: devices[dev]}
        })

        const sortedDevices = arrayDevices.sort((a: any, b: any) => {
            return b.value - a.value
        })
        return sortedDevices.slice(0, 4);
    }

    public get topThreatTypes(): any {
        const threats: any = [];

        this.data.all_alerts.forEach((alert: any) => {
            if (threats[alert.threat_type]) {
                threats[alert.threat_type]++;
            } else {
                threats[alert.threat_type] = 1;
            }
        })


        const arrayThreats = Object.keys(threats).map((threat: any) => {
            return {name: threat, value: threats[threat]}
        })


        const sortedThreats = arrayThreats.sort((a: any, b: any) => {
            return b.value - a.value
        })

        const finalThreats: any = {
            labels: [],
            values: [],
        }

        sortedThreats.slice(0, 5).forEach(((th: any) => {
            finalThreats.labels.push(th.name);
            finalThreats.values.push(th.value);
        }))

        return finalThreats;
    }

    public get allAlertsPerMonth(): any {
        const months = pastYearMonthsArray();
        this.data.all_alerts.forEach((item: any) => {
            if (months[moment.unix(item.timestamp).format('YYYYMM')]) {
                months[moment.unix(item.timestamp).format('YYYYMM')].value++;
            }
        })

        const chartData: any = {
            labels: [],
            values: []
        }

        Object.keys(months).forEach((key: any) => {
            chartData.labels.push(months[key].label)
            chartData.values.push(months[key].value)
        })

        return chartData;
    }

    onApiFailure = (e: Error) => {
        this._loading = false;
        throw e;
    }

    onLoadDataSuccess = ({items, total, metadata}: { items: Array<Alert>, total: number, metadata: Metadata }) => {
        this._data.alerts = items;
        this.totalDeviceAlertsCount = total;
        this._loading = false;
        if (metadata != null) {
            if (metadata.cursor_after != null) {
                this.data_cursor_after = metadata.cursor_after;
            } else {
                this.data_cursor_after = "";
            }
            if (metadata.cursor_before != null) {
                this.data_cursor_before = metadata.cursor_before;
            } else {
                this.data_cursor_before = "";
            }
            if (metadata.cursor_last != null) {
                this.data_cursor_last = metadata.cursor_last;
            } else {
                this.data_cursor_last = "";
            }
        }
    }

    onLoadTopAttacked = ({items, total}: { items: Array<any>, total: number }) => {
        const devices: any = [];

        items.forEach((item: any) => {
            devices[item.device.hostname] = item.total_attacks;
        })


        const arrayDevices = Object.keys(devices).map((dev: any) => {
            return {name: dev, value: devices[dev]}
        })

        const sortedDevices = arrayDevices.sort((a: any, b: any) => {
            return b.value - a.value
        })
        this.topattacked = sortedDevices.slice(0, 4)
    }

    onLoadTopThreats = ({top_threats}: { top_threats: Array<any> }) => {
        const threats: any = [];

        top_threats.forEach((alert: any) => {
            threats[alert.threat_type] = alert.total_alerts;
        })


        const arrayThreats = Object.keys(threats).map((threat: any) => {
            return {name: threat, value: threats[threat]}
        })


        const sortedThreats = arrayThreats.sort((a: any, b: any) => {
            return b.value - a.value
        })

        const finalThreats: any = {
            labels: [],
            values: [],
        }

        sortedThreats.slice(0, 5).forEach(((th: any) => {
            finalThreats.labels.push(th.name);
            finalThreats.values.push(th.value);
        }))

        this.topthreatlist = finalThreats;

    }

    onLoadAlertsPerMonth = ({alerts_per_month}: { alerts_per_month: any }) => {
        const months = pastYearMonthsArray();


        alerts_per_month.forEach((item: any) => {

            if (months[moment(item.month).format('YYYYMM')]) {
                months[moment(item.month).format('YYYYMM')].value = item.count;
            }
        })

        const chartData: any = {
            labels: [],
            values: []
        }

        Object.keys(months).forEach((key: any) => {

            chartData.labels.push(months[key].label)
            chartData.values.push(months[key].value)
        })

        this.alertspermonth = chartData;

    }

    onLoadAllDataSuccess = ({
                                items,
                                total,
                                metadata
                            }: { items: Array<Alert>, total: number, metadata: Metadata }) => {
        this._data.all_alerts = items;
        this.totalAlertsCount = total;
        this._loading = false;
        if (metadata != null) {
            if (metadata.cursor_after != null) {
                this.cursor_after = metadata.cursor_after;
            } else {
                this.cursor_after = "";
            }
            if (metadata.cursor_before != null) {
                this.cursor_before = metadata.cursor_before;
            } else {
                this.cursor_before = "";
            }
            if (metadata.cursor_last != null) {
                this.cursor_last = metadata.cursor_last;
            } else {
                this.cursor_last = "";
            }
        }
    }

    loadTopAttacked = (account_id: string, role: number) => {


        if (role === 0) {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v1/users/${account_id}/devices/top-attacked?count=5`,
                options: {method: 'GET'}
            })
                .then(this.onLoadTopAttacked)
                .catch(this.onApiFailure);
        } else {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v1/accounts/${account_id}/devices/top-attacked?count=5`,
                options: {method: 'GET'}
            })
                .then(this.onLoadTopAttacked)
                .catch(this.onApiFailure);
        }


    }

    loadAlertsPerMonth = (accountId: string, role: number) => {

        const todate = moment().format('YYYY-MM-DD');
        const fromdate = moment().subtract(1, 'years').format('YYYY-MM-DD')

        if (role === 0) {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v2/aggregations/${accountId}/alerts-per-month?date_from=${fromdate}&date_to=${todate}&severity_type=all`,
                options: {method: 'GET'}
            })
                .then(this.onLoadAlertsPerMonth)
                .catch(this.onApiFailure);

        } else {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v2/aggregations/alerts-per-month?date_from=${fromdate}&date_to=${todate}&severity_type=all`,
                options: {method: 'GET'}
            })
                .then(this.onLoadAlertsPerMonth)
                .catch(this.onApiFailure);

        }
    }

    loadTopThreats = (account_id: string, role: number) => {

        if (role === 0) {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v1/users/${account_id}/alerts/top-threat-types?count=6`,
                options: {method: 'GET'}
            })
                .then(this.onLoadTopThreats)
                .catch(this.onApiFailure);
        } else {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v1/accounts/${account_id}/alerts/top-threat-types?count=6`,
                options: {method: 'GET'}
            })
                .then(this.onLoadTopThreats)
                .catch(this.onApiFailure);
        }


    }

    loadData = (device_id: string, count: number, cursor: string, severitiesFilterSelection: Severity, categoriesFilterSelection: Category) => {
        this._loading = true;
        this.severities = severitiesFilterSelection;
        this.setCategories(categoriesFilterSelection);
        if (count === -1) {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v2/devices/${device_id}/alerts?sort=-timestamp&severities=` + translateSeverities(this.severities) + "&categories=" + this.categories + "&include_subaccounts=true",
                options: {method: 'GET'}
            })
                .then(this.onLoadDataSuccess)
                .catch(this.onApiFailure);
        } else {
            return makeAuthenticatedRequest({
                // TODO: we don't want user IDs in the URL
                url: `/api/v2/devices/${device_id}/alerts?sort=-timestamp&count=${count}&cursor=${cursor}&severities=` + translateSeverities(this.severities) + "&categories=" + this.categories + "&include_subaccounts=true",
                options: {method: 'GET'}
            })
                .then(this.onLoadDataSuccess)
                .catch(this.onApiFailure);
        }
    }

    loadAllAlerts = (count: number, cursor: string, options?: LoadOptions) => {
        this._loading = true

        // Set optional parameters
        if (options)
            this.setLoadOptions(options)

        const sort = `${this.order === 'desc' ? '-' : ''}${this.sortBy}`

        // Build url
        let url = `/api/v2/alerts?sort=${sort}&severities=${this.severities}&categories=${this.categories}`
        if (count !== -1)
            url = url.concat(`&count=${count}&cursor=${cursor}`)

        return makeAuthenticatedRequest({ url, options: {method: 'GET'}})
        .then(this.onLoadAllDataSuccess)
        .catch(this.onApiFailure);
    }
    
    // Set filters
    setLoadOptions = (options: LoadOptions) => {
        if (options?.severitiesFilter)
            this.severities = options.severitiesFilter
        if (options?.categoriesFilter)
            this.setCategories(options.categoriesFilter)
        if (options?.sortBy)
            this.sortBy = options.sortBy
        if (options?.order)
            this.order = options.order
    }

    private setCategories(categoriesFilter: string) {
        if (categoriesFilter === "hardening") {
            this.categories = "hardening";
        }
        if (categoriesFilter === "detection") {
            this.categories = "detection";
        }
        if (categoriesFilter === "mitigation") {
            this.categories = "mitigation";
        }
        if (categoriesFilter === "all") {
            this.categories = "";
        }

    }


    loadCountAlerts = (count: number) => {
        this._loading = true;

        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v1/alerts?count=${count}`,
            options: {method: 'GET'}
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    loadDeviceAlertsCSV = (device_id: string, host_name: string) => {
        //this._loading = true;
        return makeAuthenticatedDownloadRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v1/devices/${device_id}/alerts?sort=-timestamp`,
            options: {method: 'GET'},
            filename: host_name + "_alerts_report.csv"
        })
            .catch(this.onApiFailure);
    }

    loadAlertsCSV = () => {
        // this._loading = true;

        return makeAuthenticatedDownloadRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v1/alerts`,
            options: {method: 'GET',},
            filename: "alerts_report.csv"
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

}

export function translateSeverities(severitiesFilterSelection: Severity) {
    if (severitiesFilterSelection === "ransomware")
        return "critical"
    if (severitiesFilterSelection === "ids")
        return "error,alert,emergency,warning"
    if (severitiesFilterSelection === "siem")
        return "notice,informational,debug"
    return ''
}

export async function loadCurrentAlertsCount(categories: string, severities: Severity): Promise<number> {
    return makeAuthenticatedRequest({
        url: `api/v2/alerts?sort=-timestamp&count=1&cursor=&categories=${categories}&severities=${translateSeverities(severities)}`
    })
    .then(data => {
        return data.total
    })
    .catch(error => console.error(error))
}

const STORE = new AlertsStore();

export default STORE;
