import React from 'react';

import './Modal.scss';

type ModalProps = {
    title?: string;
    onClose?: () => void
};

class Modal extends React.Component<ModalProps, any> {
    componentDidMount() {
        const background = document.getElementById('Modal');

        background?.addEventListener('click', this.backgroundClickHandler);
    }

    componentWillUnmount() {
        const background = document.getElementById('Modal');

        background?.removeEventListener('click', this.backgroundClickHandler);
    }

    backgroundClickHandler = (event: any) => {
        const background = document.getElementById('Modal');
        if (event.target === background) {
            this.onCancel();
        }
    }

    onCancel = () => {
        const { onClose } = this.props;
        onClose && onClose();
    }
    
    render() {
        const { title, children } = this.props;

        return (
                <div tabIndex={-1} role="dialog" id="Dialogue" className="dialogue" >
                        <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: 'none'}}>
                        <h5 className="modal-title" style={{ marginTop: -5, textAlign: 'center' }} >{title}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={this.onCancel}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                {children}
                            </div>
                        </div>
                </div>
        );
    }
}

export default Modal;