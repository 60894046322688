import AuthStore from './AuthStore';
import DevicesStore from './DevicesStore';
import DeviceDetailStore from './DeviceDetailStore';
import PoliciesStore from './PoliciesStore';
import WindowsConfigStore from './WindowsConfigStores';
import ProcessesStore from './ProcessesStore';
import UserProfileStore from './UserProfileStore';
import UsersStore from './UsersStore';
import AlertsStore from './AlertsStore';
import ProcessesPerUserStore from './ProcessesPerUserStore';
import AccountsStore from './AccountsStore'

export default {
    AccountsStore,
    AuthStore,
    DevicesStore,
    DeviceDetailStore,
    PoliciesStore,
    WindowsConfigStore,
    ProcessesStore,
    UserProfileStore,
    UsersStore,
    AlertsStore,
    ProcessesPerUserStore
};