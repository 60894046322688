import { Box, TableCell, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from "@mui/material"
import { Order } from "stores/AlertsStore"
import { visuallyHidden } from '@mui/utils';

export interface HeadCell {
	disablePadding: boolean
	id: string
	label: string
	numeric: boolean
	sortable?: boolean
	showWhen?: () => boolean
}

interface EnhancedTableHeadProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
	order: Order
	orderBy: string;
	rowCount: number;
	headCells: readonly HeadCell[]
}

export function EnhancedTableHead(props: EnhancedTableHeadProps, ...otherProps: any[]) {
	const { order, orderBy, headCells, onRequestSort } = props
	const createSortHandler =
		(property: string) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};


	return (
		<TableHead>
			<TableRow>
				{ headCells
					.filter((headCell) => {
						if (headCell.showWhen)
							return headCell.showWhen()
						return true
					})
					.map((headCell) => (
						<StyledTableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							{ headCell.sortable ?
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							:
								headCell.label
							}
						</StyledTableCell>
					))
				}
			</TableRow>
		</TableHead>
	);
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	backgroundColor: '#232328'
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
	textAlign: 'center'
}))
