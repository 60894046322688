import { Alert, Category, LoadOptions, Metadata, Order, Severity, translateSeverities } from "./AlertsStore"

interface AlertsStore {

    parseAlerts: (rawAlerts: Alert[]) => Alert[]

}

function procCreateAlertGetMultiLineFromFields(responseMessage: string, plainTextInfo: string, description: string){
    var mulitlineArray = new Array(3)
    const commandLineBeginIndex = description.indexOf("CommandLine:")
    const commandLineEndIndex = description.indexOf("ParentImage")
    if(commandLineBeginIndex > 0 && commandLineEndIndex > 0){
        const commandLine = description.slice(commandLineBeginIndex,commandLineEndIndex)
        responseMessage = responseMessage + "Check if command line looks suspicious. "
        mulitlineArray[0] = responseMessage
        mulitlineArray[1] = commandLine
        mulitlineArray[2] = plainTextInfo
    }else{
                        mulitlineArray[0] = responseMessage
                        mulitlineArray[1] = plainTextInfo
                        mulitlineArray[2] = ""
    }
    var linesHTML  = mulitlineArray.map((line)=><div>{line}</div>)
    return linesHTML
}

function ropAlertGetMultiLineFromFields(responseMessage: string, plainTextInfo: string, description: string){
    var mulitlineArray = new Array(3)
    const commandLineBeginIndex = description.indexOf("Program Name")
    if(commandLineBeginIndex > 0){
        const commandLine = description.slice(commandLineBeginIndex)
        mulitlineArray[0] = responseMessage
        mulitlineArray[1] = "Offending program: " + commandLine
        mulitlineArray[2] = plainTextInfo
    }else{
                        mulitlineArray[0] = responseMessage
                        mulitlineArray[1] = plainTextInfo
                        mulitlineArray[2] = ""
    }
    var linesHTML  = mulitlineArray.map((line)=><div>{line}</div>)
    return linesHTML
}

function decoyAlertGetMultiLineFromFields(responseMessage: string, plainTextInfo: string, description: string){
    var mulitlineArray = new Array(3)
    const commandLineBeginIndex = description.indexOf("PID")
    if(commandLineBeginIndex > 0){
        const commandLine = description.slice(commandLineBeginIndex)
        mulitlineArray[0] = responseMessage
        mulitlineArray[1] = "Offending program: " + commandLine
        mulitlineArray[2] = plainTextInfo
    }else{
                        mulitlineArray[0] = responseMessage
                        mulitlineArray[1] = plainTextInfo
                        mulitlineArray[2] = ""
    }
    var linesHTML  = mulitlineArray.map((line)=><div>{line}</div>)
    return linesHTML
}

function entropyAlertGetMultiLineFromFields(responseMessage: string, plainTextInfo: string, description: string){
    var mulitlineArray = new Array(4)
    const commandLineBeginIndex = description.indexOf("PID")
    const extensionNameBeginIndex = description.indexOf(".")
    if(commandLineBeginIndex > 0 && extensionNameBeginIndex > 0){
        const commandLine = description.slice(commandLineBeginIndex)
        const extensionName = description.slice(extensionNameBeginIndex,commandLineBeginIndex)
        mulitlineArray = new Array(3)
        mulitlineArray[0] = responseMessage
        mulitlineArray[1] = "Suspicious extension name: " + extensionName
        mulitlineArray[2] = "Offending program: " + commandLine
        mulitlineArray[3] = plainTextInfo
    }else{
                        mulitlineArray[0] = responseMessage
                        mulitlineArray[1] = plainTextInfo
                        mulitlineArray[2] = ""
                        mulitlineArray[3] = ""
    }
    var linesHTML  = mulitlineArray.map((line)=><div>{line}</div>)
    return linesHTML
}

export function parseAlerts(rawAlerts: Alert[]) {
    var numAlerts = rawAlerts.length
    var myArray = new Array(numAlerts)
    for (let i = 0; i < numAlerts; i++) {
        myArray[i] = rawAlerts[i]
        const newStyleAlertDistinguisher = myArray[i].description.slice(0,1)
        if (newStyleAlertDistinguisher != "{"){
            if (myArray[i].category == "detection"){
                if(myArray[i].severity == "emergency"){
                    if(myArray[i].threat_type.includes("Attack=T1059.001,Technique=Powershell,Tactic=Execution,DS=Process: Process Creation,UEBA=Powershell as cmd.exe child process,Risk=39")){
                        var plainTextInfo = " Details: Powershell was started from a command prompt. This is not a normal user activity, but may be attibutable to windows updates. Please check if the timestamp falls within a regular update schedule. For further information, please look at the SIEM tab to see other events happening around this time frame. This tactic falls under the MITRE Atack Framework tactic T1059.001 referenced at https://attack.mitre.org/techniques/T1059/001/"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The command prompt and powershell were killed."
                        } else{
                            responseMessage = "Response: The command prompt and powershell were allowed to run."
                        }
                        var mulitlineAlert = procCreateAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].threat_type.includes("Attack=T1059.001,Technique=Powershell,Tactic=Execution,DS=Process: Process Creation,Level=3,Alert=Powershell ran from Scripting Utilities,Risk=50")){
                        var plainTextInfo = " Details: Powershell was started from wscript, a tool used for internet explorer. This is not a normal user activity. For further information, please look at the SIEM tab to see other events happening around this time frame. This tactic falls under the MITRE Atack Framework tactic T1059.001 referenced at https://attack.mitre.org/techniques/T1059/001/"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: Wscript and powershell were killed."
                        } else{
                            responseMessage = "Response: Wscript and powershell were allowed to run."
                        }
                        var mulitlineAlert = procCreateAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].threat_type.includes("Attack=T1059.001,Technique=Powershell,Tactic=Execution,DS=Process: Process Creation,Level=3,Alert=Powershell ran from cscript or wscript,Risk=50")){
                        var plainTextInfo = " Details: Powershell was started from wscript, a tool used for internet explorer. This is not a normal user activity. For further information, please look at the SIEM tab to see other events happening around this time frame. This tactic falls under the MITRE Atack Framework tactic T1059.001 referenced at https://attack.mitre.org/techniques/T1059/001/"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: Wscript and powershell were killed."
                        } else{
                            responseMessage = "Response: Wscript and powershell were allowed to run."
                        }
                        var mulitlineAlert = procCreateAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].threat_type.includes("Attack=T1059.001,Technique=PowerShell,Tactic=Execution,DS=Process: Process Creation,Level=4,Alert=invoke-shellcode,Risk=100")){
                        var plainTextInfo = " Details: Powershell was started and given shellcode/raw machine code to execute. This is not a normal method for running a program from powershell. Powershell is normally provided a script or an executable to run. Running the raw machine code rather than putting the machine code in an executable and running the executable is a likely attempt to evade file scanning.  For further information, please look at the SIEM tab to see other events happening around this time frame. This tactic falls under the MITRE Atack Framework tactic T1059.001 referenced at https://attack.mitre.org/techniques/T1059/001/"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: Powershell was not allowed to execute."
                        } else{
                            responseMessage = "Response: Powershell was allowed to run. The user should be instructed to reboot immediately."
                        }
                        var mulitlineAlert = procCreateAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].threat_type.includes("Attack=None,Technique=None,Tactic=Execution,DS=Process: Process Creation,Level=3,Alert=Powershell ran from an office app,Risk=50")){
                        var plainTextInfo = " Details: Powershell was started from a microsoft office application. This is not a normal user activity. For further information, please look at the SIEM tab to see other events happening around this time frame."
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The command prompt and powershell were killed."
                        } else{
                            responseMessage = "Response: The command prompt and powershell were allowed to run."
                        }
                        var mulitlineAlert = procCreateAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].description.includes("Function called is CreateProcessW; Call type is Trap")){
                        var plainTextInfo = " Details: At the point that the CreateProcess function was enterred, there was no immediate reference to it on the call stack. This means that the CreateProcess function was reached in an irregular way that is consistent with buffer overflow attacks.  More specifically, the Call instruction that preceded the immediate return address on the stack is not a call to CreateProcess."
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The offending process was killed and no new process was spawned."
                        } else{
                            responseMessage = "Response: The offending process was not killed and a new process was created."
                        }
                        var mulitlineAlert = ropAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].description.includes("Function called is VirtualProtect; Call type is Trap")){
                        var plainTextInfo = " Details: At the point that the VirtualProtect function was enterred, there was no immediate reference to it on the call stack. This means that the VirtualProtect function was reached in an irregular way that is consistent with buffer overflow attacks.  More specifically, the Call instruction that preceded the immediate return address on the stack is not a call to VirtualProtect."
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The offending process was killed."
                        } else{
                            responseMessage = "Response: The offending process was not killed and memory permissions were allowed to be changed for in process memory."
                        }
                        var mulitlineAlert = ropAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    } else if(myArray[i].description.includes("Obfuscated Suspicious Commands")){
                        var plainTextInfo = " Details: A powershell command was called that falls within a blocklist. Blocklisted commands fall into the category of downloading files, running assembly code, running obfuscated powershell, and encryption"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The command was blocked"
                        } else{
                            responseMessage = "Response: The command was allowed to run"
                        }
                        var mulitlineArray = new Array(2)
                        mulitlineArray[0] = responseMessage
                        mulitlineArray[1] = plainTextInfo
                        var mulitlineAlert  = mulitlineArray.map((line)=><div>{line}</div>)
                        myArray[i].description = mulitlineAlert
                    }
                } else if(myArray[i].severity == "critical"){
                    if(myArray[i].description.includes("Process Triggered Decoy")){
                        var plainTextInfo = " Details: A decoy file was modified. The decoy files are hidden files, and are not visible to users by default"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The offending process was killed"
                        } else{
                            responseMessage = "Response: The offending process was not killed"
                        }
                        var mulitlineAlert = decoyAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    }else if(myArray[i].description.includes("File Renaming High Entropy Score Name")){
                        var plainTextInfo = " Details: A file was renamed using a double extension that is not common"
                        var responseMessage = ""
                        if(myArray[i].mitigated){
                            responseMessage = "Response: The offending process was killed"
                        } else{
                            responseMessage = "Response: The offending process was not killed"
                        }
                        var mulitlineAlert = entropyAlertGetMultiLineFromFields(responseMessage,plainTextInfo,myArray[i].description)
                        myArray[i].description = mulitlineAlert
                    }
                }

            }
        }
    }
    return myArray
}