import React from 'react';
import { inject, observer } from 'mobx-react';

import DropDownButton from 'components/dropdown/DropDownButton';

import './User.scss'

type UserProps = {
    AuthStore?: any;
};

const User = ({ AuthStore }: UserProps) => {
    const { user } = AuthStore;

    const navigateToProfile = (event: any) => {
        event.preventDefault();
        window.location.href = '/profile'
    };

    const logOut = (event: any) => {
        event.preventDefault();
        AuthStore.logout();
    };
    return (
        <li className="nav-item dropdown user-menu">
            <DropDownButton
                size="md"
                iconCls="fas fa-user"
                containerTag="ul"
            >
                <li className="user-header bg-danger">
                    <img
                        src={user.picture || '/img/default-profile.png'}
                        className="img-circle elevation-2"
                        alt="User"
                    />
                    <p>{user.email}</p>
                </li>
                <li className="user-footer">
                    <button
                        type="button"
                        className="btn-default"
                        onClick={navigateToProfile}
                        style={{color: 'white'}}
                    >
                        Profile
                    </button>
                    <button
                        type="button"
                        className="btn-logout"
                        onClick={logOut}
                    >
                        Logout
                    </button>
                </li>
            </DropDownButton>
        </li>
    );
};

export default inject('AuthStore')(observer(User));
