import React from 'react';
import {inject, observer} from 'mobx-react';
import DataTable, { TableColumn } from 'react-data-table-component';

import LoadingComponent from 'utils/LoadingComponent';
import Col from 'react-bootstrap/Col';

import moment from 'moment';
import {FALSE} from 'sass';
import { Alert } from 'stores/AlertsStore';
import PROPERTIES from 'properties';
import EllipsisCell from 'components/EllipsisCell';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { filterMitigatedColumn } from './AlertsTable';

const { ALERTS } = PROPERTIES

type EndpointAlertsTableProps = {
    data?: any;
    id?: string;
    loadingState?: boolean;
    showSearch?: boolean;
    AlertsStore?: any;
    alertsSeverityFilter: string;
    alertsCategoryFilter: string;
};

interface AlertsEndpointsTableState {
    filtertext: string;
    totalRows: number;
    reset: boolean;
    page: number;
    perPage: number;
    data: any; // Change this to the appropriate type for the 'data' property
    expandedRows: { [key: string]: boolean };
}

const divStyle = {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
};

class EndpointAlertsTable extends React.Component<EndpointAlertsTableProps, AlertsEndpointsTableState> {
    constructor(props: any) {
        super(props)
        this.state = {
            filtertext: "",
            totalRows: 16,
            reset: false,
            page: 0,
            perPage: 10,
            data: props.data,
            expandedRows: {}
        };
    }

    handleSearch = (event: any) => {
        this.setState({filtertext: event.target.value})
    };
    handleDescriptionToggle = (rowId: string) => {
        this.setState((prevState: AlertsEndpointsTableState) => ({
            expandedRows: {
                ...prevState.expandedRows,
                [rowId]: !prevState.expandedRows[rowId], // Toggle the expanded state for the clicked row
            },
        }));
    };

    componentDidUpdate(prevProps: Readonly<any>) {
        const {AlertsStore, id} = this.props;
        if (prevProps.alertsSeverityFilter !== this.props.alertsSeverityFilter || prevProps.alertsCategoryFilter !== this.props.alertsCategoryFilter){
            //this.handlePageChange(1);
            this.setState({reset: true});
            AlertsStore.loadData(id, this.state.perPage, "", this.props.alertsSeverityFilter, this.props.alertsCategoryFilter).catch(() => {
                console.log('Error loading alerts.');
            });
            this.handlePageChange(1);
        }
    }

    componentDidMount() {
        const {AlertsStore, id} = this.props;
        const {totalDeviceAlertsCount} = AlertsStore;

        AlertsStore.loadData(id, 10, "", this.props.alertsSeverityFilter);
        this.setState({totalRows: totalDeviceAlertsCount, page: 1});


    }

    handlePageChange = (page: any) => {
        console.log("Page is changed to " + page + "from " + this.state.page);
        const {AlertsStore, id} = this.props;
        const {data_cursor_before, data_cursor_after, data_cursor_last} = AlertsStore;


        if ((page - this.state.page) == 1) {
            console.log("Next page: curser after " + data_cursor_after);
            AlertsStore.loadData(id, this.state.perPage, data_cursor_after, this.props.alertsSeverityFilter)
                .catch(() => {
                    console.log('Error loading Server devices.');
                });

        } else if ((page - this.state.page) > 1) {
            console.log("Next page: curser after " + data_cursor_last);
            AlertsStore.loadData(id, this.state.perPage, data_cursor_last, this.props.alertsSeverityFilter)
                .catch(() => {
                    console.log('Error loading Server devices.');
                });

        } else if (page === 1) {
            console.log("First page");
            AlertsStore.loadData(id, this.state.perPage, "", this.props.alertsSeverityFilter)
                .catch(() => {
                    console.log('Error loading Server devices.');
                });
        } else if ((page - this.state.page) == -1) {
            console.log("Before page");
            AlertsStore.loadData(id, this.state.perPage, data_cursor_before, this.props.alertsSeverityFilter)
                .catch(() => {
                    console.log('Error loading Server devices.');
                });
        }


        this.setState({page: page});
    }

    handlePerRowsChange = async (newPerPage: any, page: any) => {
        const {AlertsStore, id} = this.props;
        console.log("Change rows per page to " + newPerPage);
        if (page != 1) {
            this.setState({perPage: newPerPage, reset: true});
        } else {
            this.setState({perPage: newPerPage, reset: false});
            AlertsStore.loadData(id, newPerPage, "", this.props.alertsSeverityFilter)
                .catch(() => {
                    console.log('Error loading Server devices.');
                });
        }


    }


    render() {
        const {AlertsStore, alertsCategoryFilter, alertsSeverityFilter} = this.props;
        const {totalDeviceAlertsCount} = AlertsStore;

        const columns: TableColumn<Alert>[] = [
            {
                name: ALERTS.TIME,
                selector: (alert: Alert) => moment.unix(alert.timestamp).format('MM/DD/YYYY, h:mm a'),
                sortable: true,
                maxWidth: "15%",
            },
            {
                name: ALERTS.SUMMARY,
                selector: (alert: Alert) => alert.threat_type,
                sortable: true,
                cell: (alert: Alert) => <EllipsisCell text={alert.threat_type}/>,
                maxWidth: "33%",
            },
            {
                name: ALERTS.DESCRIPTION,
                selector: (alert: Alert) => alert.description,
                sortable: true,
                cell: (alert: Alert) => <EllipsisCell text={alert.description}/>,
                maxWidth: "40%",
            }
        ]

        if (filterMitigatedColumn(alertsCategoryFilter, alertsSeverityFilter))
            columns.push({
                name: ALERTS.MITIGATED,
                selector: (alert: Alert) => alert.mitigated,
                cell: (alert: Alert) => {
                    if (alert.category === 'detection' && alert.mitigated !== null)
                        return alert.mitigated ? <BsCheckCircleFill color="green" /> : <BsXCircleFill color="red" />
                    return ''
                },
                maxWidth: "8%",
                center: true
            })

        return (
            <DataTable
                columns={columns}
                data={AlertsStore.data.alerts}
                pagination
                paginationServer
                paginationResetDefaultPage={this.state.reset}
                paginationTotalRows={totalDeviceAlertsCount}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handlePerRowsChange}
                style={{borderRadius: '30px'}}
                noDataComponent={
                    <div style={{
                        backgroundColor: '#232328',
                        color: '#ffffff',
                        width: '100%',
                        padding: '1.5rem',
                        textAlign: 'center'
                    }}>
                        {this.props.loadingState && this.props.loadingState === true ?
                            <LoadingComponent/>
                            :

                            <p> There are no records to display </p>
                        }
                    </div>
                }
            />
        );
    }
}

export default inject('DeviceDetailStore', 'DevicesStore', 'AlertsStore')(observer(EndpointAlertsTable));