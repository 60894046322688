import { observable, action, computed, toJS, makeObservable } from 'mobx';


import { makeAuthenticatedRequest } from 'utils/API';
import AuthStore from './AuthStore';

type PrometheusValue = [Date, string];
type ProcessData = PrometheusValue[];

type ProcessInfo = {
    device_id: string;
    instance: string;
    job: string;
    process_local_id: string;
    user_id: string;
    __name__: string;
};

type DeviceProcess = {
    metric: ProcessInfo;
    values: ProcessData;
};

type MetricResponse = {
    status: string;
    data: {
        result: DeviceProcess[]
    }
}

type RawDetails = {
    processes: MetricResponse | null;
}

export class ProcessesStore {
    public loading: boolean;
    public totalprocesscount:number;

    private _data: RawDetails;

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            loading: observable,
            totalprocesscount: observable,

            data: computed,
            totalProcesses: computed,
            dashboardChartData: computed,

            onLoadProcessDataSuccess: action,
            loadProcessData:action,
            loadProcessCount:action,
            onLoadProcessCountSuccess: action
        });

        this.loading = false;
        this.totalprocesscount=0;
        this._data = { processes: null }
    }

    public get data(): RawDetails {
        return toJS(this._data);
    }

    public get totalProcesses(): number {
        return this.data.processes?.data.result.length || 0;
    }

    public get dashboardChartData(): any {
        const dataMap = new Map();

        const length = this.data.processes?.data.result.length || 0;

        for (let i=0; i<length; i++) {
            const results = this.data.processes?.data.result;

            const process = results && results[i];
            const values = process?.values;

            if(values){
                for (let j=0; j<values?.length; j++) {

                    const [label, value] = values[j];
                    const labelAsString = `${label}`;
    
                    if (dataMap.has(labelAsString)) {
                        const increment = parseInt(value, 10);
                        const existingValue = dataMap.get(labelAsString);
    
                        dataMap.set(labelAsString, existingValue + increment);

                    } else {
                        dataMap.set(labelAsString, parseInt(value, 10));
                    }
                }
            }
            
        }

        return {
            labels: Array.from(dataMap.keys()),
            values: Array.from(dataMap.values())
        };
    }

    onApiFailure = (e: Error) => {
        this.loading = false;

        throw e;
    }

    onLoadProcessDataSuccess = (response: MetricResponse) => {
        this._data.processes = response;
    }

    onLoadProcessCountSuccess = ({ total, items }: { total: number, items:[] }) => {
        this.totalprocesscount = total;
    }

    loadProcessData = () => {
        const now = Date.now();
        const end = now / 1000;
        // last 1 hour
        const start = end - (60 * 60 * 1);

        const metric = 'user_id'; // process_local_id
        const step = '15s';

        return makeAuthenticatedRequest({

            url: `/api/v1/metrics?query=processes{${metric}="${AuthStore?.user?.id}"}&start=${start}&end=${end}&step=${step}`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadProcessDataSuccess)
    }

    loadProcessCount = () => {
        return makeAuthenticatedRequest({
            url: `/api/v1/processes?sort=last_heartbeat&count=0`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadProcessCountSuccess)
    }
}

const STORE = new ProcessesStore();

export default STORE;