import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'App.scss';

import AllStores from 'stores/all';

import AuthenticatedRoute from 'components/AuthenticatedRoute';

import Login from 'views/login/Login';
import ResetPassword from 'views/login/ResetPassword';
import Main from 'views/main/Main';
import NotFound from 'views/NotFound/NotFound';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import StoresProvider from 'stores/StoresProvider';
import AlertsProvider from 'stores/AlertsProvider';
import ProcessesProvider from 'stores/ProcessesProvider';
import DevicesProvider from 'stores/DevicesProvider';
import AccountsProvider from 'stores/AccountsProvider';
import WindowsConfigsProvider from 'stores/WindowsConfigsProvider';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#dc3545',
      dark: '#dc3545'
    }
  },
  typography: {
    fontFamily: 'Varela Round'
  }
})

function App() {
  return (
    <ThemeProvider theme={darkTheme} >
      <CssBaseline />
      <WindowsConfigsProvider>
      <AccountsProvider>
      <DevicesProvider>
      <AlertsProvider>
      <ProcessesProvider>
      <StoresProvider>
        <div className="App">
          <Provider {...AllStores}>
            <ToastContainer />


            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/resetpassword" element={<ResetPassword />} />

              <Route
                path="/*"
                element={
                  <AuthenticatedRoute>
                    <Main />
                  </AuthenticatedRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Provider>
        </div>
      </StoresProvider>
      </ProcessesProvider>
      </AlertsProvider>
      </DevicesProvider>
      </AccountsProvider>
      </WindowsConfigsProvider>
    </ThemeProvider>
  );
}

export default App;
