import { observable, action, computed, toJS, makeObservable } from 'mobx';


import { makeAuthenticatedRequest } from 'utils/API';
import { toastSuccess } from 'utils/Toast';
import AuthStore from './AuthStore';
import DevicesStore from './DevicesStore';

type RunningProcess = {
    id: string;
    device_id: string;
    local_id: number;
    last_heartbeat: string;
    process_name: string;
    entropy_score: number;
};

type PrometheusValue = [Date, string];
type ProcessData = PrometheusValue[];

type ProcessInfo = {
    device_id: string;
    instance: string;
    job: string;
    process_local_id: string;
    user_id: string;
    __name__: string;
};

type DeviceProcess = {
    metric: ProcessInfo;
    values: ProcessData;
};

type MetricResponse = {
    status: string;
    data: {
        result: DeviceProcess[]
    }
}

type DeviceDetails = {
    id?: any;
    ip_address?: string;
    account_id?: string;
    account_name?: string;
    user_id?: string;
    hostname?: string;
    policy_id?: string;
    entropy_score?:number;
    last_heartbeat?:string;
    windows_device_configuration_id?:string;
    agent_version?:string;
    type?:string;
    ransomware_protection_restore_from_backup?: boolean

    processes: RunningProcess[];

    metrics: MetricResponse | null;

    randomization: MetricResponse | null;
}

type DeviceResponse = {
    account_id: string
    account_name: string
    agent_version: string
    hostname: string
    id: string
    ip_address: string
    last_heartbeat: string
    policy_id: string
    ransomware_protection_restore_from_backup: boolean
    type: string
    user_id: string
    windows_device_configuration_id: string
}

class DeviceDetailStore {
    public _loading: boolean;

    private _data: DeviceDetails;

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            _loading: observable,

            data: computed,
            loading: computed,
            totalCpu: computed,
            totalProcesses: computed,
            processLabels: computed,
            applicationsRandomized: computed,
            cpuByProcess: computed,
            randomizationByProcess: computed,

            onApiFailure: action,
            onLoadMetricDataSuccess: action,
            onLoadProcessDataSuccess: action,
            onDetailsSync: action,
            loadData: action,
            onLoadDataSuccess: action,
            onLoadRandomizationDataSuccess:action,
            loadProcessData: action,
            //loadMetricData: action,
            //loadRandomizationData: action,
            syncDetailsForDevice: action,
            onAssignPolicySuccess: action,
            assignPolicy: action,
        });

        this._loading = false;
        this._data = {
            id: '',
            ip_address: '',
            account_name: '',
            account_id: '',
            user_id: '',
            hostname: '',
            entropy_score: 0,
            agent_version: '',
            last_heartbeat: '',
            type: '',
            windows_device_configuration_id: '',
            processes: [],
            metrics: null,
            randomization: null,
            ransomware_protection_restore_from_backup: false
        };
    }

    public get loading(): boolean {
        return toJS(this._loading);
    }

    public get data(): DeviceDetails {
        return toJS(this._data);
    }

    public get totalCpu(): number {
        let cpu = 0;

        this.data.metrics?.data.result.forEach((deviceProcess: DeviceProcess) => {
            // Prometheus might return more/different data compared to the processes in the DB

            if (this.data.processes.find(process => process.local_id.toString() === deviceProcess.metric.process_local_id)) {
                const value: PrometheusValue = deviceProcess.values[deviceProcess.values.length - 1];
                cpu += parseInt(value[1]);
            }
        });

        return cpu;
    }

    public get applicationsRandomized(): number {
        return this.data.processes.length;
    }

    public get totalProcesses(): number {
        return this.data.processes.length;
    }

    public get processes(): any[] {
        return this.data.processes;
    }

    public get processLabels(): string[] {
        return this.data.processes.map(process => process.process_name);
    }

    public get cpuByProcess(): any[] {
        const data = [];

        for (let i=0; i<this.processLabels.length; i++) {
            const label = this.processLabels[i];
            const foundProcess = this.data.processes.find(el => el.process_name === label);

            // const { local_id } = this.data.processes.find(el => el.process_name === label);

            // no strict-equality because int-vs-string
            const metric = this.data.metrics?.data.result.find(el => {
                return el.metric.process_local_id === foundProcess?.local_id.toString()
            });

            if (metric?.values) {
                const process:{processName:any, labels:any[], data:any[], process_local_id:any} = {
                    processName: label,
                    labels: [],
                    data: [],
                    process_local_id: foundProcess?.local_id,
                };

                for (let j=0; j<metric.values.length; j++) {

                    process.labels.push(metric.values[j][0])


                    process.data.push({
                        x: metric.values[j][0],
                        y: metric.values[j][1],
                    })
                }

                data.push(process);
            }
        }

        return data;
    }

    public get randomizationByProcess(): any {
        const data: {labels: any[], values: any[]} = {
            labels: [],
            values: []
        };

        for (let i=this.processLabels.length-1; i>=0; i--) {
            const label = this.processLabels[i];

            const foundId = this.data.processes.find(el => el.process_name === label);
            // const { local_id } = this.data.processes.find(el => el.process_name === label);

            // no strict-equality because int-vs-string
            const deviceProcess = this.data.randomization?.data.result.find(el => {
                return el.metric.process_local_id === foundId?.local_id.toString();
            });

            if (deviceProcess?.values) {

                data.labels.push(label);

                const processData = deviceProcess.values;
                const prometheusValue = processData[deviceProcess.values.length - 1][1]

                data.values.push(prometheusValue);
            }
        }

        return data;
    }

    onApiFailure = (e: Error) => {
        this._loading = false;

        throw e;
    }

    onLoadMetricDataSuccess = (response: MetricResponse) => {
        this._data.metrics = response;
    }


    onLoadProcessDataSuccess = ({ items, total }: { items: RunningProcess[], total: number }) => {
        this._data.processes = items;
    }

    onLoadRandomizationDataSuccess = (response: MetricResponse) => {
        this._data.randomization = response;
    }

    loadProcessData = () => {
        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v1/users/${AuthStore.user?.id}/devices/${this.data.id}/processes`,
            options: { method: 'GET' }
        })
            .then(this.onLoadProcessDataSuccess);
    }

    loadMetricData = () => {
        const now = Date.now();
        const end = now / 1000;
        // last hour
        const start = end - (60 * 60);

        const metric = 'device_id'; // process_local_id
        const step = '15s';

        return makeAuthenticatedRequest({
            // LIST METRICS PER PROCESS
             //url: `/api/v1/metrics?query=process_cpu_usage{process_local_id="48"}&start=${(now/1000) - (60 * 60)}&end=${now/1000}&step=15s`,

            // list devices and processes?
            url: `/api/v1/metrics?query=process_cpu_usage{${metric}="${this.data.id}"}&start=${start}&end=${end}&step=${step}`,
            options: { method: 'GET' }
        })
            .then(this.onLoadMetricDataSuccess)
    }

    loadRandomizationData = () => {
        const now = Date.now();
        const end = now / 1000;
        // last hour
        const start = end - (60 * 60);

        const metric = 'device_id'; // process_local_id
        const step = '15s';


        return makeAuthenticatedRequest({
            // LIST METRICS PER PROCESS
            // url: `/api/api_v1/metrics?query=process_cpu_usage{process_local_id="35"}&start=${(now/1000) - (60 * 60)}&end=${now/1000}&step=15s`,

            // list devices and processes?
            url: `/api/v1/metrics?query=average_randomization_interval{${metric}="${this.data.id}"}&start=${start}&end=${end}&step=${step}`,
            options: { method: 'GET' }
        })
            .then(this.onLoadRandomizationDataSuccess)
    }

    onDetailsSync = () => {

        const device = DevicesStore.getDeviceById(this.data.id);

        this._data.ip_address = device?.ip_address;
        this._data.account_id = device?.account_id;
        this._data.account_name = device?.account_name
        this._data.user_id = device?.user_id;
        this._data.hostname = device?.hostname;
        this._data.entropy_score = device?.entropy_score;
        this._data.last_heartbeat = device?.last_heartbeat;
        this._data.windows_device_configuration_id = device?.windows_device_configuration_id;
        this._data.type = device?.type;
        this._data.agent_version = device?.agent_version;
        this._data.ransomware_protection_restore_from_backup = device?.ransomware_protection_restore_from_backup
    }

    syncDetailsForDevice = () => {
        return DevicesStore.loadData()
            .then(this.onDetailsSync)
    }

    onLoadDataSuccess = () =>{
        this._loading = false;
    }

    loadData = (device_id: string): Promise<any> => {
        this._loading = true;

        this._data.id = device_id;

        return Promise.all([
            this.syncDetailsForDevice(),
            this.loadProcessData(),
            //this.loadMetricData(),
            //this.loadRandomizationData()
        ])
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);

    }

    onAssignPolicySuccess = (policyId: string) => {
        this._data.windows_device_configuration_id = policyId
        toastSuccess('Policy assigned to device!');
    }

    assignPolicy = (policyId: string) => {
        let url = `/api/v2/accounts/${this.data.account_id}/devices/${this.data.id}/windows_device_configurations?windows_device_configuration_id=${policyId}`
        if (!this.data.account_id)
            url = `/api/v1/users/${this.data.user_id}/devices/${this.data.id}/windows_device_configurations?windows_device_configuration_id=${policyId}`
        return makeAuthenticatedRequest({
            url: url,
            options: { method: 'PATCH' },
        })
            .then(() => this.onAssignPolicySuccess(policyId))
            .catch(this.onApiFailure);
    }

    assignRansomWareProtection = (value: boolean) => {
        let url = `/api/v2/accounts/${this.data.account_id}/devices`
        if (!this.data.account_id)
            url = `/api/v1/users/${this.data.user_id}/devices`

        return makeAuthenticatedRequest({
            url,
            options: { method: 'PUT' },
            data: {
                ...this.data,
                ransomware_protection_restore_from_backup: value
            }
        })
        .then(this.onAssignRansomwareProtectionSuccess)
        .catch(this.onApiFailure)
    }

    onAssignRansomwareProtectionSuccess = (response: DeviceResponse) => {
        console.log({response})
        this._data.ransomware_protection_restore_from_backup = response.ransomware_protection_restore_from_backup
    }


    public get calculateCpu() :any {
        const processesCpu = this.cpuByProcess.map((item) => {
            const processEntropy = this.data.processes.filter((process) => process.local_id === item.process_local_id
                )

            return {processName: item.processName, cpuPercentage: item.data[item.data.length-1].y, entropyScore: processEntropy[0].entropy_score}
        })

        const sortedProcesses = processesCpu.sort((a, b) => { return parseInt(b.cpuPercentage) - parseInt(a.cpuPercentage) });

        const firstFourProcesses = sortedProcesses.slice(0, 4);
        const totalCpu = firstFourProcesses.reduce((total: any, item: any) => total + parseInt(item.cpuPercentage), 0)
        return {first_processes: firstFourProcesses, total_cpu: totalCpu}}
}

const STORE = new DeviceDetailStore();

export default STORE;
