import { createContext, useContext, useState } from 'react'
import { makeAuthenticatedRequest } from 'utils/API'

export type WindowsConfig = {
    id: string
    account_name: string
    account_id: string
    allowlist: string
    protected_processes: string[]
    name: string
    shared_with?: { account_name: string, account_id: string }[]
    required_tier: number
}

interface WindowsConfigsStore {
    loading: boolean
    windowsConfigs: WindowsConfig[]
    loadData: (accountId?: string) => Promise<void>
    create: (data: {protected_processes: string, name: string}, accountId?: string) => Promise<void>
    deleteConfig: (data: WindowsConfig) => Promise<void>
    setConfigToAllEndpoints: (accountId: string, windowsDeviceConfigurationId: string) => Promise<void>
    shareConfigWithSubaccounts: (windowsDeviceConfigurationId: string, subaccounts: string[]) => Promise<void>
}

const WindowsConfigsContext = createContext<WindowsConfigsStore | undefined>(undefined)

export function useWindowsConfigsStoreContext() {
    const stores = useContext(WindowsConfigsContext)
    if (stores === undefined)
        throw new Error('useWindowsConfigsStoreContext must be use wrapped around a WindowsConfigsProvider')
    return stores
}

export default function WindowsConfigsProvider({ children }: {children: any}) {
    const [loading, setLoading] = useState<boolean>(false)
    const [windowsConfigs, setWindowsConfigs] = useState<WindowsConfig[]>([])
    const [loadedAccountId, setLoadedAccountId] = useState<string>('')

    async function loadData(accountId?: string): Promise<any> {
        setLoading(true)

        let url = `/api/v2/windows_device_configurations`
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/windows_device_configurations`
        url = url.concat('?include_shared_info=True&include_subaccounts=True')

        return makeAuthenticatedRequest({ url })
            .then(onLoadDataSuccess)
            .then(() => {
                if (accountId)
                    setLoadedAccountId(accountId)
            })
            .catch(onApiFailure)
    }

    function onLoadDataSuccess({ items }: { items: WindowsConfig[], total: number }) {
        let i = 0
        while(i<items.length){
            let j = 0
            while(j < items[i].protected_processes.length){
                let numeral = j + 1 
                if(items[i].allowlist === undefined){
                    items[i].allowlist = numeral.toString() + ". " + items[i].protected_processes[j] + "  "
                }else{
                    items[i].allowlist = items[i].allowlist + numeral.toString() + ". " + items[i].protected_processes[j] + "  "
                }
                j++
            }
            i++
        }
        setWindowsConfigs(items)
        setLoading(false)
    }

    async function create(data: {protected_processes: string, name: string}, accountId?: string): Promise<any> {
        setLoading(true)

        let url = '/api/v2/windows_device_configurations'
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/windows_device_configurations`

        return makeAuthenticatedRequest({
            url,
            options: { method: 'POST' },
            data
        })
        .then(() => loadData(loadedAccountId))
        .catch(onApiFailure)
    }

    async function deleteConfig(data: WindowsConfig): Promise<any> {
        setLoading(true)

        return makeAuthenticatedRequest({
            url: `/api/v2/windows_device_configurations/${data.id}`,
            options: { method: 'DELETE' },
            data
        })
        .then(() => loadData(loadedAccountId))
        .catch(onApiFailure)
    }

    async function setConfigToAllEndpoints(accountId: string, windowsDeviceConfigurationId: string) {
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${accountId}/devices/windows_device_configurations?windows_device_configuration_id=${windowsDeviceConfigurationId}`,
            options: { method: 'PATCH' }
        })
    }

    async function shareConfigWithSubaccounts(windowsDeviceConfigurationId: string, subaccounts: string[]) {
        return makeAuthenticatedRequest({
            url: `/api/v2/windows_device_configurations/${windowsDeviceConfigurationId}/permissions`,
            options: { method: 'POST' },
            data: { subaccount_ids: subaccounts }
        })
    }

    function onApiFailure(e: Error) {
        setLoading(false)
        const errorMessageJSON = JSON.parse(e.message)
        const errorMessageText = errorMessageJSON.detail[0].msg
        const errorMessageTextUppercase = errorMessageText.charAt(0).toUpperCase() + errorMessageText.slice(1)
        throw errorMessageTextUppercase
    }

    return (
        <WindowsConfigsContext.Provider value={{
            loading,
            windowsConfigs,
            loadData,
            create,
            deleteConfig,
            setConfigToAllEndpoints,
            shareConfigWithSubaccounts
        }}>
            {children}
        </WindowsConfigsContext.Provider>
    )
}
