import { createContext, useContext, useState } from "react"
import { makeAuthenticatedDownloadRequest, makeAuthenticatedRequest } from "utils/API"
import { Alert, Category, LoadOptions, Metadata, Order, Severity, translateSeverities } from "./AlertsStore"
import { AlertKey } from "views/main/dashboard/AlertsTable"
import moment from "moment"
import { pastYearMonthsArray } from "utils/Date"
import { parseAlerts } from "./AlertParser"

interface AlertsStore {
    loading: boolean
    alerts: Alert[]
    total: number
    topAttacked: AttackedDevice[]
    topRansomwareAttacked: AttackedDevice[]
    topThreats: TopThreats
    alertsPerMonth: { labels: string[], values: number[] }
    idsAlertsPerDay: ChartData
    ransomwareAlertsPerDay: ChartData
    cursorBefore: string
    cursorAfter: string
    cursorLast: string
    severity: Severity
    category: Category
    order: Order
    orderBy: AlertKey
    mitigation: Mitigation
    loadAlerts: (count: number, cursor: string, accountId?: string) => void
    loadTopIDSAttacked: (accountId: string) => void
    loadTopRansomwareAttacked: (accountId: string) => void
    loadTopThreats: (accountId: string, role: string) => void
    loadAlertsPerMonth: (accountId: string, role: string) => void
    loadAlertsPerDay: (accountId: string, role: string) => void
    getTopThreatsCountByType: () => TopThreats
    downloadAlertsCSV: () => void
    setSeverity: (severity: Severity) => void
    setCategory: (category: Category) => void
    setOrder: (order: Order) => void
    setOrderBy: (orderBy: AlertKey) => void
    setMitigation: (mitigation: Mitigation) => void
    setLoadOptions: (options: LoadOptions) => void
    getAlertsCount: (severity: Severity, mitigated?: boolean, accountId?: string) => Promise<number>
}

type ChartData = {
    labels: string[]
    values: number[]
}

const empytChartData = {
    labels: [],
    values: []
} as ChartData

const AlertsContext = createContext<AlertsStore | undefined>(undefined)

export function useAlertsStoreContext() {
    const stores = useContext(AlertsContext)
    if (stores === undefined)
        throw new Error('useAlertsStoreContext must be use wrapped around a AlertsProvider')
    return stores
}

export default function AlertsProvider({ children }: {children: any}) {
    const [alerts, setAlerts] = useState<Alert[]>([])
    const [total, setTotal] = useState<number>(0)
    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState<AlertKey>('timestamp')
    const [loading, setLoading] = useState<boolean>(false)
    const [severity, setSeverity] = useState<Severity>('ransomware')
    const [category, setCategory] = useState<Category>('detection')
    const [mitigation, setMitigation] = useState<Mitigation>('all')
    const [topIDSAttacked, setTopIDSAttacked] = useState<AttackedDevice[]>([])
    const [topRansomwareAttacked, setTopRansomwareAttacked] = useState<AttackedDevice[]>([])
    const [topThreats, setTopThreats] = useState<TopThreats>({labels: [], values: []})
    const [alertsPerMonth, setAlertsPerMonth] = useState({labels: [], values: []})
    const [idsAlertsPerDay, setIDSAlertsPerDay] = useState<ChartData>(empytChartData)
    const [ransomwareAlertsPerDay, setRansomwareAlertsPerDay] = useState<ChartData>(empytChartData)
    const [cursorBefore, setCursorBefore] = useState<string>('')
    const [cursorAfter, setCursorAfter] = useState<string>('')
    const [cursorLast, setCursorLast] = useState<string>('')

    async function loadAlerts(count: number, cursor: string, accountId?: string) {
        setLoading(true)

        // Build url
        const sort = `${order === 'desc' ? '-' : ''}${orderBy}`
        let url = `/api/v2/alerts`
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/alerts`

        // Set query params
        url = url.concat(`?sort=${sort}&severities=${translateSeverities(severity)}&categories=${category}&include_subaccounts=true`)
        if (count !== -1)
            url = url.concat(`&count=${count}&cursor=${cursor}`)
        if (mitigation !== 'all')
            url = url.concat(`&mitigated=${mitigationTranslation[mitigation]}`)

        return makeAuthenticatedRequest({ url, options: {method: 'GET'}})
            .then(onLoadAlerts)
            .catch(onApiFailure)
    }

    function onLoadAlerts({items, total, metadata}: AlertsResponse) {
        var plaintextArray = parseAlerts(items)
        setAlerts(plaintextArray)
        setTotal(total)
        setCursors(metadata)
        setLoading(false)
    }

    function setCursors(metadata: Metadata) {
        if (metadata != null) {
            if (metadata.cursor_after != null)
                setCursorAfter(metadata.cursor_after)
            else
                setCursorAfter('')
            if (metadata.cursor_before != null)
                setCursorBefore(metadata.cursor_before)
            else
                setCursorBefore('')
            if (metadata.cursor_last != null)
                setCursorLast(metadata.cursor_last)
            else
                setCursorLast('')
        }
    }

    async function loadTopIDSAttacked(accountId: string) {
        const url = `/api/v2/accounts/${accountId}/devices/top-attacked?count=5&severities=${translateSeverities('ids')}&include_subaccounts=true`
        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadTopAttacked(response as TopAttackedResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadTopAttacked(response: TopAttackedResponse) {
        const attackedDevices: AttackedDevice[] = response.items.map((item) => {
            return { id:item.device.id,hostname: item.device.hostname, totalAttacks: item.total_attacks}
        })
        const sortedDevices = attackedDevices.sort((a: AttackedDevice, b: AttackedDevice) => {
            return b.totalAttacks - a.totalAttacks
        })
        if(sortedDevices.length == 0){
            setTopIDSAttacked([])
        }else if(sortedDevices[0].totalAttacks == 0){
            setTopIDSAttacked([])
        }else if(sortedDevices.length == 1){
            setTopIDSAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices[1].totalAttacks == 0){
            setTopIDSAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices.length == 2){
            setTopIDSAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices[2].totalAttacks == 0){
            setTopIDSAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices.length == 3){
            setTopIDSAttacked(sortedDevices.slice(0, 3))
        }else if(sortedDevices[3].totalAttacks == 0){
            setTopIDSAttacked(sortedDevices.slice(0, 3))
        }else{
            setTopIDSAttacked(sortedDevices.slice(0, 4))
        }
    }

    async function loadTopRansomwareAttacked(accountId: string) {
        const url = `/api/v2/accounts/${accountId}/devices/top-attacked?count=5&severities=${translateSeverities('ransomware')}&include_subaccounts=true`
        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadTopRansomwareAttacked(response as TopAttackedResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadTopRansomwareAttacked(response: TopAttackedResponse) {
        const attackedDevices: AttackedDevice[] = response.items.map((item) => {
            return { id: item.device.id, hostname: item.device.hostname, totalAttacks: item.total_attacks}
        })
        const sortedDevices = attackedDevices.sort((a: AttackedDevice, b: AttackedDevice) => {
            return b.totalAttacks - a.totalAttacks
        })
        if(sortedDevices.length == 0){
            setTopRansomwareAttacked([])
        }else if(sortedDevices[0].totalAttacks == 0){
            setTopRansomwareAttacked([])
        }else if(sortedDevices.length == 1){
            setTopRansomwareAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices[1].totalAttacks == 0){
            setTopRansomwareAttacked(sortedDevices.slice(0, 1))
        }else if(sortedDevices.length == 2){
            setTopRansomwareAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices[2].totalAttacks == 0){
            setTopRansomwareAttacked(sortedDevices.slice(0, 2))
        }else if(sortedDevices.length == 3){
            setTopRansomwareAttacked(sortedDevices.slice(0, 3))
        }else if(sortedDevices[3].totalAttacks == 0){
            setTopRansomwareAttacked(sortedDevices.slice(0, 3))
        }else{
            setTopRansomwareAttacked(sortedDevices.slice(0, 4))
        }
    }


    function onApiFailure(e: Error) {
        setLoading(false)
        throw e
    }

    async function loadTopThreats(accountId: string, role: string) {
        let url = ''
        if (role === 'admin')
            url = `/api/v1/users/${accountId}/alerts/top-threat-types?count=5`
        else
            url = `/api/v1/accounts/${accountId}/alerts/top-threat-types?count=5`

        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadTopThreats(response as TopThreatsResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadTopThreats(response: TopThreatsResponse) {
        const sortedThreats = response.top_threats.sort((a: Threat, b: Threat) => {
            return b.total_alerts - a.total_alerts
        })

        const labels: string[] = []
        const values: number[] = []

        sortedThreats.slice(0, 5).forEach((threat => {
            labels.push(threat.threat_type);
            values.push(threat.total_alerts);
        }))

        setTopThreats({ labels, values })
    }

    async function loadAlertsPerDay(accountId: string, role: string) {
        loadAlertsPerDayBySeverity(accountId, role, 'ids').then(onLoadAlertsPerDay).then(setIDSAlertsPerDay)
        loadAlertsPerDayBySeverity(accountId, role, 'ransomware').then(onLoadAlertsPerDay).then(setRansomwareAlertsPerDay)
    }

    async function loadAlertsPerDayBySeverity(accountId: string, role: string, severity: Severity) {
        const todate = moment()
        const fromdate = moment().subtract(14, 'days')

        let url = ''
        if (role === 'admin')
            url = `/api/v2/accounts/${accountId}/alerts-per-day`
        else
            url = `/api/v2/alerts-per-day`
        url = url.concat(`?date_from=${fromdate.format('YYYY-MM-DD')}&date_to=${todate.format('YYYY-MM-DD')}&severities=${translateSeverities(severity)}&categories=detection&timezone=${getTimeZone()}&include_subaccounts=true`)

        return makeAuthenticatedRequest({ url }).then((response) => {
            return {response: response as AlertsPerDayResponse, fromdate, todate}
        })
    }

    function onLoadAlertsPerDay({response, fromdate, todate}: {response: AlertsPerDayResponse, todate: moment.Moment, fromdate: moment.Moment}){
        const numberOfDays = todate.diff(fromdate, 'days')

        // Build labels for every day since starting date
        const labels: string[] = []
        const nextdate = fromdate
        for (let day = 0; day < numberOfDays; day++)
            labels.push(nextdate.add(1, 'days').format('MM/DD/YYYY'))

        // Tranfsorm response into a map
        const countByDay = new Map<string, number>()
        response.alerts_per_day.forEach((elem) => {
            const reformat = moment(elem.day).format('MM/DD/YYYY')
            countByDay.set(reformat, elem.count)
        })

        // Fill values based on map
        const values: number[] = []
        labels.forEach((day) => {
            const count = countByDay.get(day)
            if (count)
                values.push(count)
            else
                values.push(0)
        })

        return { labels, values }
    }

    async function loadAlertsPerMonth(accountId: string, role: string) {
        const todate = moment().format('YYYY-MM-DD');
        const fromdate = moment().subtract(1, 'years').format('YYYY-MM-DD')

        let url = ''
        if (role === 'admin')
            url = `/api/v2/aggregations/${accountId}/alerts-per-month?date_from=${fromdate}&date_to=${todate}&severity_type=all`
        else
            url = `/api/v2/aggregations/alerts-per-month?date_from=${fromdate}&date_to=${todate}&severity_type=all`

        return (makeAuthenticatedRequest({ url }))
            .then(response => {
                return onLoadAlertsPerMonth(response as AlertsPerMonthResponse)
            })
            .catch(onApiFailure)
    }

    function onLoadAlertsPerMonth(response: AlertsPerMonthResponse){
        const months = pastYearMonthsArray();
        response.alerts_per_month.forEach((item) => {

            if (months[moment(item.month).format('YYYYMM')]) {
                months[moment(item.month).format('YYYYMM')].value = item.count;
            }
        })

        const chartData: any = {
            labels: [],
            values: []
        }

        Object.keys(months).forEach((key: any) => {
            chartData.labels.push(months[key].label)
            chartData.values.push(months[key].value)
        })

        setAlertsPerMonth(chartData)
    }

    function getTopThreatsCountByType() {
        const threats: any = [];

        alerts.forEach((alert: any) => {
            if (threats[alert.threat_type]) {
                threats[alert.threat_type]++;
            } else {
                threats[alert.threat_type] = 1;
            }
        })


        const arrayThreats = Object.keys(threats).map((threat: any) => {
            return {name: threat, value: threats[threat]}
        })


        const sortedThreats = arrayThreats.sort((a: any, b: any) => {
            return b.value - a.value
        })

        const finalThreats: any = {
            labels: [],
            values: [],
        }

        sortedThreats.slice(0, 5).forEach(((th: any) => {
            finalThreats.labels.push(th.name);
            finalThreats.values.push(th.value);
        }))

        return finalThreats;
    }

    async function loadAlertsCSV() {
        return makeAuthenticatedDownloadRequest({
            url: `/api/v2/alerts`,
            filename: "alerts_report.csv"
        })
        .catch(onApiFailure);
    }

    // Set filters
    function setLoadOptions(options: LoadOptions) {
        if (options?.severitiesFilter)
            setSeverity(options.severitiesFilter);
        if (options?.categoriesFilter)
            setCategory(options.categoriesFilter)
        if (options?.sortBy)
            setOrderBy(options.sortBy)
        if (options?.order)
            setOrder(options.order)
        if (options?.mitigation)
            setMitigation(options.mitigation)
    }

    async function getAlertsCount(severity: Severity, mitigated?: boolean, accountId?: string) {
        // Set base url
        let url = `/api/v2/alerts`
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/alerts`

        // Add query params
        url = url.concat(`?severities=${translateSeverities(severity)}&categories=detection&count=10&sort=-timestamp&include_subaccounts=true`)
        if (mitigated !== undefined)
            if (mitigated)
                url = url.concat(`&mitigated=True`)
            else    
                url = url.concat(`&mitigated=False`)

        return makeAuthenticatedRequest({ url })
            .then((response: AlertsResponse) => response.total)
    }

    return (
        <AlertsContext.Provider value={{
            loading,
            alerts,
            total,
            topAttacked: topIDSAttacked,
            topRansomwareAttacked,
            topThreats,
            alertsPerMonth,
            idsAlertsPerDay,
            ransomwareAlertsPerDay,
            cursorBefore,
            cursorAfter,
            cursorLast,
            severity,
            category,
            order,
            orderBy,
            mitigation,
            loadAlerts,
            loadTopIDSAttacked,
            loadTopRansomwareAttacked,
            loadTopThreats,
            loadAlertsPerMonth,
            loadAlertsPerDay,
            getTopThreatsCountByType,
            downloadAlertsCSV: loadAlertsCSV,
            setSeverity,
            setCategory,
            setOrder,
            setOrderBy,
            setMitigation,
            setLoadOptions,
            getAlertsCount
        }}>
            { children }
        </AlertsContext.Provider>
    )
}

interface TopAttackedResponse {
    total: number
    items: TopAttackedItem[]
}

type TopAttackedItem = {
    device: {
        id: string,
        ip_address: string,
        user_id: string,
        policy_id: string,
        type: string,
        hostname: string,
        windows_device_configuration_id: string,
        entropy_score: number,
        last_heartbeat: string,
        total_alerts: number,
        agent_version: string
    },
    total_attacks: number
}

export type AttackedDevice = {
    id: string,
    hostname: string,
    totalAttacks: number
}

interface TopThreatsResponse {
    top_threats: Threat[]
}

type Threat = {
    threat_type: string,
    total_alerts: number
}

interface TopThreats {
    labels: string[]
    values: number[]
}

interface AlertsPerMonthResponse {
    alerts_per_month: {
        month: string,
        count: number
    }[]
}

interface AlertsPerDayResponse {
    alerts_per_day: {
        day: string,
        count: number
    }[]
}

type AlertsResponse = {
    items: Alert[],
    total: number,
    metadata: Metadata
}

export type Mitigation = 'blocked' | 'unblocked' | 'all'

const mitigationTranslation = {
    'blocked': 'True',
    'unblocked': 'False',
    'all': ''
}

function getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}
