import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';

type Policy = {
    id: string;
    randomization_interval_seconds: string;
    name: string;
}

class PoliciesStore {
    public loading: boolean;

    private _data: any[];

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            loading: observable,

            data: computed,

            onApiFailure: action,
            onLoadDataSuccess: action,

            loadData: action
        });

        this.loading = false;
        this._data = [];
    }

    public get data(): Policy[] {
        return toJS(this._data);
    }

    onApiFailure = (e: Error) => {
        const errorMessageJSON = JSON.parse(e.message);
        const errorMessageText = errorMessageJSON.detail[0].msg;
        const errorMessageTextUppercase = errorMessageText.charAt(0).toUpperCase() + errorMessageText.slice(1)
        this.loading = false;
        throw errorMessageTextUppercase;
    }

    onLoadDataSuccess = ({ items }: { items: Policy[], total: number }) => {
        this._data = items;
        this.loading = false;
    }

    loadData = (): Promise<any> => {
        this.loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v1/policies`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    create = (data: Policy): Promise<any> => {
        this.loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v1/policies`,
            options: { method: 'POST' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }

    delete = (data: Policy): Promise<any> => {
        this.loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v1/policies/${data.id}`,
            options: { method: 'DELETE' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }
}

const STORE = new PoliciesStore();

export default STORE;