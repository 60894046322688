import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { toastFail, toastSuccess } from 'utils/Toast';

import Card from 'components/Card';
import Modal from 'components/Modal';

import './AddPolicyModal.scss';
import { useWindowsConfigsStoreContext } from 'stores/WindowsConfigsProvider';

interface AddPolicyModalProps {
    onCancel: () => void
    accountId?: string
}

export default function AddPolicyModal({ onCancel, accountId }: AddPolicyModalProps) {
    const windowsConfigsStore = useWindowsConfigsStoreContext()

    function onSave(e: any) {
        const { form } = e.target

        // these will be HTML <input /> elements, not the raw values
        const { name, protected_processes } = form

        e.preventDefault()

        windowsConfigsStore.create({
            name: name.value,
            protected_processes: protected_processes.value
        }, accountId)
        .then(() => {
            toastSuccess('Policy saved!')
            windowsConfigsStore.loadData(accountId)
            onCancel()
        })
        .catch((e:any) => toastFail(e))
    }

    return (
        <Modal title={'Create List'} onClose={onCancel}>
            <Card>
                <form >
                    <FieldsetColumn>
                        <label className='allow-list-label' htmlFor="name">List Name</label>
                        <input
                            name="name"
                            id="name"
                            type="text"
                            placeholder="Default Policy"
                            className="form-control"
                            style={{ backgroundColor: "#343a40", color: 'white' }}
                        />
                        
                        <label className='allow-list-label' htmlFor="randomization_interval_seconds">Comma Separated List of Programs</label>
                        <textarea id="protected_processes" name="protected_processes"
                                rows={5} cols={33}>
                        </textarea>
                    </FieldsetColumn>

                    <button className="btn btn-primary" type="button" onClick={onSave}>Save</button>
                    <button className="btn btn-secondary" type="button" onClick={onCancel}>Close</button>
                </form>
            </Card>
        </Modal>
    )
}

const FieldsetColumn = styled('fieldset')`
    input {
        margin-bottom: 20px;
    }
`
