export enum KEYS {
    JWT = "JWT",
    USER = "USER"
}

const getSavedValue = (key: KEYS, hydrateData: boolean = false) => {
    const data = window.localStorage.getItem(key);

    if (data === null) {
        return data;
    }

    return hydrateData ? JSON.parse(data) : data;
}

const deleteSavedValue = (key: KEYS) => {
    return window.localStorage.removeItem(key);
}

const saveValue = (key: KEYS, value: any, stringifyData: boolean = false) => {
    if (stringifyData) {
        const stringData = JSON.stringify(value);
        return window.localStorage.setItem(key, stringData);
    }
    
    return window.localStorage.setItem(key, value);
}

export {
    getSavedValue,
    deleteSavedValue,
    saveValue
}