import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Dashboard from 'views/main/dashboard/Dashboard';

import Endpoints from 'views/main/endpoints/Endpoints';
import Setup from 'views/main/setup/Setup';
import EndpointMonitoring from 'views/main/endpoints/monitoring/Monitoring';

import Users from 'views/main/users/Users';
import Accounts from 'views/main/accounts/Accounts';
import NotFound from 'views/NotFound/NotFound';
import Profile from 'views/main/profile/Profile';

import Header from './header/Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

import './Main.scss';
import Settings from './settings/Settings';
import Alerts from './alerts/Alerts';
import MemoryProtectionLists from './allow-lists/MemoryProtectionLists';


const Main = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    useEffect(() => {
        const root = document.getElementById('root')?.classList;
        if(root){
            root.add('sidebar-mini');
            root.add('sidebar-layout-fixed');
            
            root.remove('sidebar-closed');
            root.remove('sidebar-collapse');
            root.remove('sidebar-open');
            
            if (isSidebarOpen) {
                root.add('sidebar-open');
            } else {
                root.add('sidebar-collapse');
            }
        }
       
    }, [isSidebarOpen]);

    return (
        <div className="Main">
            <Header />

            <Sidebar isOpen={isSidebarOpen} toggleMenuSidebar={toggleSidebar}/>

            <div className="content-wrapper">
                
                <section>
                    <Routes>
                        <Route path="/endpoints" element={<Endpoints />} />
                        <Route path="/setup" element={<Setup />} />

                        <Route path="/endpoint/:id/monitoring" element={<EndpointMonitoring />} />
                        {/* <Route path="/endpoint/:id/risk" element={<Endpoints />} /> */}

                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/memory-protection" element={<MemoryProtectionLists />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path='/alerts' element={<Alerts />} />
                        <Route
                            path="/users"
                            element={
                                <AuthenticatedRoute requiresAdmin>
                                    <Users />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/accounts"
                            element={
                                <AuthenticatedRoute requiresAdmin>
                                    <Accounts />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route path="/" element={<Dashboard />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default Main;
