import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';
import AuthStore from './AuthStore';
import AlertsStore from './AlertsStore';
import { COMPUTED_STRUCT } from 'mobx/dist/internal';

// optional properties because CREATE action does not require them
type DeviceAlerts = {
    total?: string;
}

export type Metadata = {
    cursor_before?: any;
    cursor_after?: any;
    cursor_last?: any;
    page_length?: number;
}

export type Device = {
    id?: string;
    account_id: string;
    account_name: string;
    ip_address: string;
    user_id?: string;
    hostname?:string;
    alerts?: DeviceAlerts;
    entropy_score?: number;
    last_heartbeat?: string;
    windows_device_configuration_id?:string;
    windows_configuration_name?:string;
    agent_version?:string;
    type?: string;
    total_alerts?: number;
    ransomware_protection_restore_from_backup: boolean
}

export class DevicesStore {
    public loading: boolean;

    private _data: any[];
    private _servers: any[];
    private _desktops: any[];
    private _containers: any[];
    public totalservercount: number;
    public totaldesktopcount: number;
    public totalcontainercount: number;
    public cursor_before: string;
    public cursor_after: string;
    public cursor_last: string;
    public desktop_cursor_last: string;
    public container_cursor_last: string;
    public current_cursor: string;
    public current_desktop_cursor: string;
    public current_container_cursor: string;
    public desktop_cursor_before: string;
    public desktop_cursor_after: string;
    public container_cursor_before: string;
    public container_cursor_after: string;

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            _servers: observable,
            _desktops: observable,
            _containers: observable,
            loading: observable,
            totalservercount: observable,
            totaldesktopcount: observable,
            totalcontainercount: observable,
            cursor_before: observable,
            cursor_after: observable,
            current_container_cursor: observable,
            current_cursor: observable,
            current_desktop_cursor: observable,
            desktop_cursor_before: observable,
            desktop_cursor_after: observable,
            container_cursor_before: observable,
            container_cursor_after: observable,
            cursor_last: observable,
            desktop_cursor_last: observable,
            container_cursor_last: observable,

            data: computed,
            servers: computed,
            desktops: computed,
            containers: computed,

            onApiFailure: action,
            onLoadDataSuccess: action,
            onLoadServerSuccess: action,
            onLoadDesktopSuccess: action,
            onLoadContainerSuccess: action,

            loadData: action,
            loadServerData: action,
            loadDesktopData: action,
            loadContainerData: action,
            delete: action
        });

        this.loading = false;
        this._data = [];
        this._servers = [];
        this._desktops=[];
        this._containers=[];
        this.totalservercount=0;
        this.totaldesktopcount=0;
        this.totalcontainercount=0;
        this.cursor_after="";
        this.cursor_before="";
        this.cursor_last="";
        this.desktop_cursor_last="";
        this.container_cursor_last="";
        this.desktop_cursor_after="";
        this.desktop_cursor_before="";
        this.container_cursor_after="";
        this.container_cursor_before="";
        this.current_container_cursor="";
        this.current_cursor="";
        this.current_desktop_cursor="";
    }

    public get data(): Device[] {
        return toJS(this._data);
    }
    public get servers(): Device[] {
        return toJS(this._servers);
    }
    public get desktops(): Device[] {
        return toJS(this._desktops);
    }
    public get containers(): Device[] {
        return toJS(this._containers);
    }

    getDeviceById = (id: string) => {
        return this.data.find(device => device.id === id);
    }

    public get totalDevices(): number {
        return this.data.length;
    }

    // public get deviceTotalAlerts():any {
    //     this.data.forEach((item:Device, index: number) => {
    //         AlertsStore.loadData(item.id)
    //         this._data[index].alerts = {
    //             total: AlertsStore.totalAlerts
    //         };
    //     })
    //     return this.data;
        
    // }

    onApiFailure = (e: Error) => {
        this.loading = false;
        throw e;
    }

    onLoadDataSuccess = ({ items, total }: { items: Device[], total: number }) => {
        this._data = items;
        this.loading = false;
    }

    onLoadServerSuccess = ({ items, total,metadata }: { items: Device[], total: number, metadata:Metadata }) => {
        // console.log(total);
        // console.log(items);
        this._servers = items;
        this.totalservercount=total;
        this.loading = false;
        if (metadata!=null){
        if (metadata.cursor_after!=null)
        {
            this.cursor_after=metadata.cursor_after;
        }
        else{
            this.cursor_after="";
        }
        if (metadata.cursor_before!=null)
        {
            this.cursor_before=metadata.cursor_before;
        }
        else{
            this.cursor_before="";
        }
        if (metadata.cursor_last!=null)
        {
            this.cursor_last=metadata.cursor_last;
        }
        else{
            this.cursor_last="";
        }
    }

    }

    onLoadDesktopSuccess = ({ items, total,metadata }: { items: Device[], total: number, metadata:Metadata }) => {
        // console.log(total);
        // console.log(items);
        this._desktops = items;
        this.totaldesktopcount=total;
        this.loading = false;
        if (metadata!=null){
        if (metadata.cursor_after!=null)
        {
            this.desktop_cursor_after=metadata.cursor_after;
        }
        else{
            this.desktop_cursor_after="";
        }
        if (metadata.cursor_before!=null)
        {
            this.desktop_cursor_before=metadata.cursor_before;
        }
        else{
            this.desktop_cursor_before="";
        }
        if (metadata.cursor_last!=null)
        {
            this.desktop_cursor_last=metadata.cursor_last;
        }
        else{
            this.desktop_cursor_last="";
        }
    }

    }

    onLoadContainerSuccess = ({ items, total,metadata }: { items: Device[], total: number, metadata:Metadata }) => {
        // console.log(total);
        // console.log(items);
        this._containers = items;
        this.totalcontainercount=total;
        this.loading = false;
        if (metadata!=null){
        if (metadata.cursor_after!=null)
        {
            this.container_cursor_after=metadata.cursor_after;
        }
        else{
            this.container_cursor_after="";
        }
        if (metadata.cursor_before!=null)
        {
            this.container_cursor_before=metadata.cursor_before;
        }
        else{
            this.container_cursor_before="";
        }
    }

    }


    loadData = (): Promise<any> => {
        this.loading = true;

        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v2/devices?include_subaccounts=true`,
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    loadServerData = (count:number,cursor:string, sortBy: string = 'hostname'): Promise<any> => {
        this.loading = true;

        this.current_cursor=cursor;
        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v2/devices?sort=${sortBy}&count=${count}&cursor=${cursor}&device_type=server&include_subaccounts=true`,
            options: { method: 'GET' }
        })
            .then(this.onLoadServerSuccess)
            .catch(this.onApiFailure);
    }
    loadDesktopData = (count:number,cursor:string, sortBy: string = 'hostname'): Promise<any> => {
        this.loading = true;
        this.current_desktop_cursor=cursor;

        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v2/devices?sort=${sortBy}&count=${count}&cursor=${cursor}&device_type=desktop&include_subaccounts=true`,
            options: { method: 'GET' }
        })
            .then(this.onLoadDesktopSuccess)
            .catch(this.onApiFailure);
    }
    loadContainerData = (count:number,cursor:string, sortBy: string = 'hostname'): Promise<any> => {
        this.loading = true;
        this.current_container_cursor=cursor;

        return makeAuthenticatedRequest({
            // TODO: we don't want user IDs in the URL
            url: `/api/v2/devices?sort=${sortBy}&count=${count}&cursor=${cursor}&device_type=container&include_subaccounts=true`,
            options: { method: 'GET' }
        })
            .then(this.onLoadContainerSuccess)
            .catch(this.onApiFailure);
    }

    delete = (data: Device): Promise<any> => {
        this.loading = true;
        let url = `/api/v2/accounts/${data.account_id}/devices/${data.id}`
        if (!data.account_id)
            url = `/api/v1/users/${data.user_id}/devices/${data.id}`

        return makeAuthenticatedRequest({
            url,
            options: { method: 'DELETE' }
        })
        .catch(this.onApiFailure)
    }
}

const STORE = new DevicesStore();

export default STORE;