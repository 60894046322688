import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Checkbox, ListItemText, OutlinedInput, Select, SelectChangeEvent, SxProps } from '@mui/material';

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

interface StyledMultiSelectProps {
  label?: string
  handleOnChange: (value: string[]) => void
  options: { value: string, label?: string }[]
  value?: string[]
  className?: string
  sx?: SxProps
  renderValue?: (selected: string[]) => string
}

export default function StyledMultiSelect({ label, handleOnChange, options, value = [], className, sx, renderValue }: StyledMultiSelectProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    console.log(event.target.value)
    handleOnChange(event.target.value as string[])
  }

  return (
    <Box className={className} sx={{...defaultBoxStyle, ...sx}} >
      <FormControl sx={{ m: 1 }}>
        <InputLabel id="multiple-select-label">{label ? label : ''}</InputLabel>
        <Select
          labelId="multiple-select-label"
          id="multiple-select"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={renderValue ? renderValue : (selected) => selected.join(', ')}
          MenuProps={menuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value.indexOf(option.value) > -1} />
              <ListItemText primary={option.label ? option.label : option.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const defaultBoxStyle = { 
  minWidth: 160,
  marginTop: '10px',
  marginRight: '20px'
}

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function renderSelectedOptions() {

}