import React from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';

type AuthenticatedRouteProps = {
    AuthStore?: any;
    requiresAdmin?: boolean;
    requiresSupervisor?: boolean;
};

class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps, any> {
    render() {
        const { AuthStore, children, requiresAdmin, requiresSupervisor } = this.props;

        if (!AuthStore.isAuthenticated) {
            return (<Navigate replace to="/login" />);
        }

        if (requiresAdmin && !AuthStore.isAdmin) {
            return (<Navigate replace to="/" />);
        } else if (requiresSupervisor && !AuthStore.isSupervisor) {
            return (<Navigate replace to="/" />);
        }

        return (<>{children}</>);
    }
}

export default inject('AuthStore')(observer(AuthenticatedRoute));